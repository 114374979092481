import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { ProjectLink, Registration, Projects, Templates, Exports, ProjectJSON, DefaultProjectJSON, AssetsData, UploadAssetsData, createThumbnailData, exportProjectData, webHookData, createSubtitleData, subtitleWebhookData, projectHistoryData, tempUserPlan, translateWebhookData, userWorkSpaceListData, workspace_memberData, workspaceProjectData, workspace_invitationData, user_config } from '../provider/api_data/api.config';
// import mime from "mime-types";

export const BASE_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_URL
export const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL
export const SERVER_STATUS = process.env.REACT_APP_SERVER_STATUS

export const GetRegistration = async () => {
  let resultState = { state: '', data: {} };
  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + '/registration/me/',
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Registration;
  }

  return resultState;
}

export const GetEmailMarketing = async (marketable, notifiable) => {
  let resultState = { state: '', data: {} };
  let preferenceApi = `${BASE_BACKEND_URL}/registration/email_preferences/?marketing_email=${marketable}&notification_email=${notifiable}`;
  if (typeof marketable === 'undefined' && typeof notifiable === 'undefined'){
    preferenceApi = `${BASE_BACKEND_URL}/registration/email_preferences/`;
  }
  await axios({
    method: 'get',
    url: preferenceApi,
    data: {},
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  }).then(res => {
    console.log("email market api", res);
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data = err.data;
  });
  return resultState;
}

export const verifyLoginCode = async (projectUUID) => {
  let resultState = { state: '', data: {} };
  let userdata = { "email": document.getElementById('email_input').value, "code": document.getElementById('code_input').value };

  let project_uuid = projectUUID ? projectUUID : "";

  await axios({
    method: 'post',
    url: `${BASE_BACKEND_URL}/registration/verify_login_code`,
    data: userdata,
    withCredentials: true
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data = err.response.data;
  })

  return resultState;
};

export const requestSignInCode = async (projectUUID) => {
  let resultState = { state: '', data: {} };

  let email = document.getElementById('email_input').value;
  let project_uuid = projectUUID ? projectUUID : "";

  await axios({
    method: 'get',
    url: `${BASE_BACKEND_URL}/registration/request_sign_in_code?email=${encodeURIComponent(email)}&project_uuid=${project_uuid}`,
    data: {},
    withCredentials: true
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data = err.response.data;
  })

  return resultState;
};

export const requestSignUpCode = async (projectUUID) => {
  let resultState = { state: '', data: {} };

  let username = document.getElementById('email_input').value;
  let name_input = document.getElementById('name_input');
  let name = name_input ? name_input.value : "";
  let surname_input = document.getElementById('surname_input');
  let surname = surname_input ? surname_input.value : "";
  let project_uuid = projectUUID ? projectUUID : "";

  let user_data = { "email": username, "name": name, "surname": surname, "username": username, "project_uuid": project_uuid };

  await axios({
    method: 'post',
    url: `${BASE_BACKEND_URL}/registration/request_sign_up_code`,
    data: user_data,
    withCredentials: true
  }).then(res => {
    resultState.state = 'success';
    resultState.data.code = res.data.code;
    resultState.data.status = res.status;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data = err.response.data;
  })

  return resultState;
};

export const EmailSendEmailMagicLinkForSignIn = async (projectUUID) => {
  let resultState = { state: '', data: {} };

  let username = document.getElementById('email_input').value;
  let project_uuid = projectUUID ? projectUUID : "";

  await axios({
    method: 'get',
    url: `${BASE_BACKEND_URL}/registration/get_magic_link_for_sign_in/?username=${username}&project_uuid=${project_uuid}`,
    data: {},
    withCredentials: true
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data = err.data;
  })

  return resultState;
}

export const EmailSendEmailMagicLinkForSignUp = async (projectUUID) => {
  let resultState = { state: '', data: {} };

  let username = document.getElementById('email_input').value;
  let name_input = document.getElementById('name_input');
  let name = name_input ? name_input.value : "";
  let surname_input = document.getElementById('surname_input');
  let surname = surname_input ? surname_input.value : "";
  let project_uuid = projectUUID ? projectUUID : "";

  await axios({
    method: 'get',
    url: `${BASE_BACKEND_URL}/registration/get_magic_link_for_sign_up/?username=${username}&email=${username}&name=${name}&surname=${surname}&project_uuid=${project_uuid}`,
    data: {},
    withCredentials: true
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data['message'] = err.message;
  })

  return resultState;
}

export const GetProjectLink = async (callBack) => {
  let resultState = { state: '', data: {} };
  console.log(BASE_BACKEND_URL + `/project/`);

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: `${BASE_BACKEND_URL}/project/`,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    });
  } else {
    resultState.state = 'success';
    resultState.data = ProjectLink;
  }

  return resultState;
}

export const CreateAssets = async (project_uuid, original_filename, mime_type, category, thumbnailInfo) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/asset/create/?category=${category}&original_filename=${original_filename}&mime_type=${mime_type}&title=%22` + project_uuid + `%22&size=0&thumbnail_info=${thumbnailInfo}&project_uuid=${project_uuid}`,
      data: customer_data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
      ...AssetsData,
      uuid: uuidv4(),
      category: category,
    };
  }

  return resultState;
}

export const UploadAssets = async (P_uuid, A_uuid) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data };

  if (SERVER_STATUS !== 'localhost') {
    console.log(`${BASE_BACKEND_URL}/asset/member/?project_uuid=${P_uuid}&asset_uuid=${A_uuid}`);
    await axios({
      method: 'post',
      url: `${BASE_BACKEND_URL}/asset/member/?project_uuid=${P_uuid}&asset_uuid=${A_uuid}`,
      data: customer_data,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = UploadAssetsData;
    resultState.data.uuid = A_uuid;
  }

  return resultState;
}

export const UploadVideo = async (file, signedurl, uploading) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'put',
      url: signedurl,
      data: file,
      headers: { 'Content-Type': file.type, },
      onUploadProgress: uploading
    }).then(function (response) {
      resultState.state = 'success';
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else resultState.state = 'success';

  return resultState;
}

export const SubtitleVideo = async (asset_uuid, project_uuid) => {
  let resultState = { state: '', data: {} };

  await axios({
    method: 'get',
    url: `${BASE_BACKEND_URL}/subtitle/submit/1/${project_uuid}/${asset_uuid}/`,
    data: {},
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data['message'] = err.message;
  })

  return resultState;
}

export const CreditSubscribe = async (info, planMonthly, stripe, userData, coupon, freeTrial) => {
  let resultState = { state: '', data: {} };
  let customer_resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  console.log('useringData', user_data);
  let customer_data = { user: user_data, coupon: coupon};

  await axios({
    method: 'get',
    url: BASE_BACKEND_URL + '/payment/customer',
    data: customer_data,
    withCredentials: true,
  }).then(async (res) => {
    customer_resultState.state = 'success';
    customer_resultState.data = res.data;


    console.log("info.product : " + info.product + " planMonthly : " + info.plan);
    let product = parseInt(info.product);
    if (info.plan == "annualy" && product > 0 && product <= 3) {
      product += 3
    }
    console.log("product : " + product + " planMonthly : " + info.plan);

    let subscription_data = { user: user_data, coupon: coupon, is_free_trial: freeTrial,  name: userData.name, email: userData.email, surname: userData.surname, card: info.card, product: product, payment_method_id: info.payment_method_id };
    await axios({
      method: 'post',
      url: BASE_BACKEND_URL + `/payment/subscription`,
      headers: {
        "Content-Type": 'application/json'
      },
      data: subscription_data,
      withCredentials: true
    }).then(async (res) => {
      let data = res.data

      console.log("clientsecret : " + data.clientsecret);

      if (data.clientsecret.startsWith("seti_")) {

        console.log("await stripe.confirmCardSetup");

        await stripe.confirmCardSetup(data.clientsecret, {
          payment_method: {
            card: info.card_element,
            billing_details: info.billingDetails
          },
        }).then((result) => {
          if (result.error) {
            alert(result.error.message);

            resultState.state = 'error';
            resultState.data['message'] = result.error.message;

            console.log("stripe.confirmCardPayment result.error");
            console.log(result.error.message);
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your Purchase is succesful",
              showConfirmButton: true,
              confirmButtonText: "Okay",
            }).then((result) => {
              if (result.isConfirmed) {

                resultState.state = 'success';
                resultState.data = res.data;
                window.location.reload();
              }
            });

          }
        }).catch((err) => {
          resultState.state = 'error';
          resultState.data['message'] = err.message;

          console.log("confirmCardSetup EXCEPTION");
          console.log(resultState.data['message']);
        })

      } else if (data.clientsecret.startsWith("pi_")) {

        console.log("await stripe.confirmCardPayment");

        await stripe.confirmCardPayment(data.clientsecret, {
          payment_method: {
            card: info.card_element,
            billing_details: info.billingDetails
          },
        }).then((result) => {
          if (result.error) {
            alert(result.error.message);

            resultState.state = 'error';
            resultState.data['message'] = result.error.message;

            console.log("stripe.confirmCardPayment result.error");
            console.log(result.error.message);
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your Purchase is succesful",
              showConfirmButton: true,
              confirmButtonText: "Okay",
            }).then((result) => {
              if (result.isConfirmed) {

                resultState.state = 'success';
                resultState.data = res.data;
                window.location.reload();
              }
            });

          }
        }).catch((err) => {
          resultState.state = 'error';
          resultState.data['message'] = err.message;

          console.log("confirmCardPayment EXCEPTION");
          console.log(resultState.data['message']);
        })

      }

    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
      window.toastr.error(err.message);
    })

    console.log("/payment/subscription END");

  });

  return resultState;
}

export const getCustomerPlan = async () => {
  let user_data = getAccessToken();
  let customer_data = { user: user_data };
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + '/payment/customer',
      data: customer_data,
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
      window.toastr.error(err.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = tempUserPlan;
  }

  return resultState;
}

export const getPaymentPortal = async () => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + '/payment/create_portal_session',
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
      window.toastr.error(err.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
      portal_url: 'https://billing.stripe.com/p/session/test_YWNjdF8xR0pldHdMUzZTQU5WY3LDLF90V0pmN3VvbnLPdGcwNnhaZW9GWVJQc0RkxubVL10100kGy2ksSU'
    }
  }

  return resultState;
}

export const getProjects = async (url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Projects['localhost'];
  }

  return resultState;
}

export const getTemplates = async (url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Templates['localhost'];
  }

  return resultState;
}

export const getExports = async (url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Exports['localhost']; // TODO: return exports data
  }

  return resultState;
}

export const getAssets = async (url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Exports['localhost']; // TODO: return asets data
  }

  return resultState;
}

export const getCompress = async (url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Exports['localhost']; // TODO: return compress data
  }

  return resultState;
}

export const getTranscodes = async (url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: {},
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = Exports['localhost']; // return transcodes data
  }

  return resultState;
}

export const duplicateProject = async (PROECT_UUID) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: `${BASE_BACKEND_URL}/project/${PROECT_UUID}/duplicate/`,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success'
    resultState.data = {
      'Duplicated Project uuid': '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9',
      'Source Project uuid': '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
    }
  }

  return resultState;
}

export const duplicateTemplateProject = async (PROECT_UUID) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: `${BASE_BACKEND_URL}/templateproject/${PROECT_UUID}/duplicate/`,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success'
    resultState.data = {
      'Duplicated Project uuid': '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9',
      'Source Project uuid': '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
    }
  }

  return resultState;
}

export const addTemplateProject = async (PROJECT_UUID, TEMPLATE_UUID) => {
  let resultState = {state: '', date: {}};

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: `${BASE_BACKEND_URL}/templateproject/${TEMPLATE_UUID}/add_to_current_project_via_tooltap/${PROJECT_UUID}`,
      withCredentials: true
    }).then(res => {
      console.log('addingTemplate', res);
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data = {message: err.message};
    })
  }else{
    resultState.state = 'success'
    resultState.data = {
      'Duplicated Project uuid': '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9',
      'Source Project uuid': '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
    }
  }

  return resultState;
}

export const getProjectJSONWithME = async (project_uuid) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/project/` + project_uuid + `/?include_me=true`,
      data: {},
      headers: { 'Content-Type': 'application/octet-stream' },
      withCredentials: true,
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = ProjectJSON[project_uuid] ? ProjectJSON : { [project_uuid]: DefaultProjectJSON };
  }

  return resultState;
}
// upload helper
export const getProjectJSON = async (project_uuid) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/project/` + project_uuid + `/`,
      data: {},
      headers: { 'Content-Type': 'application/octet-stream' },
      withCredentials: true,
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = ProjectJSON[project_uuid] ? ProjectJSON : { [project_uuid]: DefaultProjectJSON };
  }

  return resultState;
}

export const updateProjectJSON = async (project_uuid, projectJSON) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    let user_data = getAccessToken();
    let data = { [project_uuid]: projectJSON, user: user_data, }
    await axios({
      method: 'post',
      url: BASE_BACKEND_URL + `/project/` + project_uuid + `/`,
      data: data,
      headers: { 'Content-Type': 'application/json' },
    }).then(function (response) {
      resultState.state = 'success'
      resultState.data = response.data
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = ProjectJSON[project_uuid] ? ProjectJSON : { [project_uuid]: DefaultProjectJSON };
  }

  return resultState
}

export const createThumbnail = async (asset_uuid, timestamps, length) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: `${BASE_BACKEND_URL}/asset/${asset_uuid}/thumbnail/packsize/${length}/timestamps/"${timestamps.toString()}"/blob/`,
      data: {},
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = createThumbnailData
  }

  return resultState;
}

export const createProjectThumbnail = async (project_uuid) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/project/` + project_uuid + `/blob/thumbnail/`,
      headers: { 'Content-Type': 'application/octet-stream' },
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data,
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }
    })
  } else {
    resultState = {
      state: 'success',
      data: createThumbnailData,
    }
  }

  return resultState;
}

export const uploadThumbnail = async (signedurl, image) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'put',
      url: signedurl,
      data: image,
      headers: { 'Content-Type': 'blob' },
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = [];
  }

  return resultState;
}
// end

export const getWebHookData = async (webhook_url) => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: webhook_url,
      data: {},
      headers: { 'Content-Type': 'application/octet-stream' },
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = webHookData;
  }

  return resultState;
}

// subtitle creating API start
export const createSubtitle = async (projectUUID, assetUUID, language, languageTo, flag) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data };

  let url = BASE_BACKEND_URL
  if (!flag) url += `/subtitle/submit/${projectUUID}/${assetUUID}/${language}`
  else url += `/subtitle/submit/${projectUUID}/${assetUUID}/${language}/${languageTo}/1`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: url,
      data: customer_data,
      withCredentials: true,
      headers: { 'Content-Type': 'application/octet-stream' },
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = createSubtitleData;
  }

  return resultState;
}

export const subtitleWebHookPoll = async (projectUUID, assetUUID, subtitleUUID, index, translate) => {
  let resultState = { state: '', data: {} };
  let url = `${BASE_BACKEND_URL}/subtitle/webhook/${subtitleUUID}/${projectUUID}/${assetUUID}/${index}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: index === 3 ? 'post' : 'get',
      url: url,
      data: {},
      headers: { 'Content-Type': 'application/octet-stream' },
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    if (!translate) resultState.data = subtitleWebhookData
    else resultState.data = translateWebhookData
  }

  return resultState;
}
// subtitle creating API end

const getAccessToken = () => {
  let userData = localStorage.getItem('user_data');
  if (!!userData && userData.length) {
    userData = JSON.parse(userData);
    if (!!userData && typeof userData?.token === 'string' && !!userData.token.length)
      return { username: userData.username, uuid: userData.uuid, token: userData.token };
    else return 'AnonymousUser';
  } else return 'AnonymousUser';
}

export const getProjectHistory = async (projectUUID, type, sequence) => {
  let resultState = { state: '', data: {} };
  let url = `${BASE_BACKEND_URL}/project/${projectUUID}/${type}/${sequence}`;

  console.log(url);
  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get', url: url, data: {},
      headers: { 'Content-Type': 'application/octet-stream' },
      withCredentials: true,
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = projectHistoryData;
  }

  return resultState;

}


export const getBranchName = async () => {
  let resultState = { state: '', data: {} };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + '/registration/settings/',
      headers: { 'Content-Type': 'application/octet-stream' },
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    resultState.data = { render_branch: "staging" }
  }

  return resultState;
}


// workspace apis
export const ShareProject = async (workspace_uuid, user_email, project_uuid) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/add_member/'
  url += `?workspace_uuid=${workspace_uuid}&project_uuid=${project_uuid}&user_email=${user_email}&level=2`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
    }
  }

  return resultState;
}

export const getWorkspacesList = async () => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/get_workspaces_for_user/'

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: userWorkSpaceListData
    }
  }

  return resultState;
}

export const getMembersList = async (workspace_uuid) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/get_workspace_members/'
  url += `?workspace_uuid=${workspace_uuid}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: workspace_memberData
    }
  }

  return resultState;
}

export const getInvitationList = async (workspace_uuid) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/get_invitation_list_for_workspace/'
  url += `?workspace_uuid=${workspace_uuid}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: workspace_invitationData
    }
  }

  return resultState;
}

export const getProjectList = async (workspace_uuid) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/get_projects_for_workspace/'
  url += `?workspace_uuid=${workspace_uuid}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: workspaceProjectData
    }
  }

  return resultState;
}

export const createWorkspace = async () => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/create/'

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: "Workspace Zeng's workspace-3 is created."
    }
  }

  return resultState;
}

export const deleteWorkspace = async (workspace_uuid) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/delete/'
  url += `?workspace_uuid=${workspace_uuid}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: "Workspace with title Zeng's workspace-3 is deleted"
    }
  }

  return resultState;
}

export const transferOwner = async (workspace_uuid, user_email) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/transfer_owner/'
  url += `?workspace_uuid=${workspace_uuid}&user_email=${user_email}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: "owner changed"
    }
  }

  return resultState;
}

export const removeMember = async (workspace_uuid, user_email) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/remove_member/'
  url += `?workspace_uuid=${workspace_uuid}&user_email=${user_email}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
    }
  }

  return resultState;
}

export const removeInvitation = async (workspace_uuid, user_email) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/remove_invitation/'
  url += `?workspace_uuid=${workspace_uuid}&user_email=${user_email}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
    }
  }

  return resultState;
}

export const memberLevelChange = async (workspace_uuid, user_email, level) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/change_member_level/'
  url += `?workspace_uuid=${workspace_uuid}&user_email=${user_email}&level=${level}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: 'change level successed'
    }
  }

  return resultState;
}

export const ChangeProjectWorkspace = async (project_uuid, uuid, target) => {
  let resultState = { state: '', data: {} };
  let url = BASE_BACKEND_URL + '/workspace/operation/change_project_workspace/'
  url += `?workspace_uuid=${uuid}&project_uuid=${project_uuid}&workspace_target_uuid=${target}`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = {
        state: 'success',
        data: response.data
      }
    }).catch(function (error) {
      resultState = {
        state: 'error',
        message: error.message
      }

      window.toastr.error(error.message);
    })
  } else {
    resultState = {
      state: 'success',
      data: 'change workspace successed'
    }
  }

  return resultState;
}

export const compressVideo = async (projectUUID, options) => {
  const searchParams = new URLSearchParams(options);
  const queryString = searchParams.toString()
  const resultState = { state: '', data: {} };
  const user_data = getAccessToken();
  const customer_data = { user: user_data };

  if (SERVER_STATUS !== 'localhost') {
    let apiUrl = BASE_BACKEND_URL + `/project/` + projectUUID + `/compress/false/0/false/false?${queryString}`

    await axios({
      url: apiUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      data: customer_data,
      withCredentials: true,
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    exportProjectData.uuid = projectUUID;
    resultState.data = exportProjectData;
  }

  return resultState;
}

export const convertVideo = async (projectUUID, options) => {
  const searchParams = new URLSearchParams(options);
  const queryString = searchParams.toString()
  const resultState = { state: '', data: {} };
  const user_data = getAccessToken();
  const customer_data = { user: user_data };

  if (SERVER_STATUS !== 'localhost') {
    let apiUrl = BASE_BACKEND_URL + `/project/` + projectUUID + `/convert/false/0/false/false?${queryString}`

    await axios({
      url: apiUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      data: customer_data,
      withCredentials: true,
    }).then(function (response) {
      resultState.state = 'success';
      resultState.data = response.data;
    }).catch(function (error) {
      resultState.state = 'error';
      resultState.data.message = error.message;
      window.toastr.error(error.message);
    })
  } else {
    resultState.state = 'success';
    exportProjectData.uuid = projectUUID;
    resultState.data = exportProjectData;
  }

  return resultState;
}

export const requestTemplate = async (PROJECT_UUID, CATEGORY, VISIBILITY, SIZE, ACTIVE) => {
  let resultState = { state: '', data: {} };
  let url = `${BASE_BACKEND_URL}/templateproject/project/${PROJECT_UUID}/${CATEGORY}/${VISIBILITY}/${SIZE}/${ACTIVE}/`

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const deleteProject = async (projectUUID) => {
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/purge/delete/project/${projectUUID}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const deleteAsset = async (assetUUID) => {
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/purge/delete/asset/${assetUUID}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const getApikeysConfig = async () => {
  let resultState = {state: '', data: {}};
  const user_data = getAccessToken();
  const url = `${BASE_BACKEND_URL}/user/config/`;

  const data = {
    user: user_data,
  }

  if(SERVER_STATUS !== 'localhost')
    await axios({
      url: url,
      method: 'GET',
      data: data,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (err) {
      resultState = {state: 'error', message: err.message}
    })
  else {
    resultState = { state: 'success', data: user_config }
  }

  return resultState;
}

export const saveApikeysConfig = async (apiKeyData) => {
  let resultState = {state: '', data: {}};
  const url = `${BASE_BACKEND_URL}/user/config/`;
  const user_data = getAccessToken();
  const data = {
    user: user_data,
    config: {
      adobe_stock_access_token: apiKeyData.adobe,
      youtube_api_key: apiKeyData.youtube,
      facebook_api_key: apiKeyData.facebook,
      twitter_api_key: apiKeyData.twitter,
      instagram_api_key: apiKeyData.instagram,
      tiktok_api_key: apiKeyData.tiktok,
      linkedin_api_key: apiKeyData.linkedin
    }
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: data
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (err) {
      resultState = {state: 'error', message: err.message}
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const deleteAccount = async (userUUID) => {
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/purge/delete/user/${userUUID}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const measureFileSize = async (assetUUID) => {
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/asset/${assetUUID}/update/`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const getStockSearchKeyword = async (keyword) => {
  const resp = await axios.get(`${BASE_BACKEND_URL}/stock/searchkeyword?context=${keyword}`);
  return resp.data;
}

export const getStockData = async (stockName) => {
  
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/stock/search?context=${stockName}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}
export const getFreeSoundSearch = async () => {
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/stock/freesound/search_audio/?context=`;

  await axios({
    url: url,
    method: 'GET',
    withCredentials: true
  }).then(function (response) {
    resultState = { state: 'success', data: response.data }
  }).catch(function (err) {
    resultState = {state: 'error', message: err.message}
    //window.toastr.error(err.message);
  })
  
  return resultState;
}
export const getPopularPixaBayStockData = async (page) => {
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/stock/pixabay/popular_videos/?page=${page}`;

  await axios({
    url: url,
    method: 'GET',
    withCredentials: true
  }).then(function (response) {
    resultState = { state: 'success', data: response.data }
  }).catch(function (err) {
    resultState = {state: 'error', message: err.message}
    //window.toastr.error(err.message);
  })
  
  return resultState;
}

export const getPopularPexelsData = async (page) => {
  
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/stock/pexels/popular/?page=${page}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}

export const getPexelsData = async (keyword, page, locale="en-US", orientation="landscape") => {
  
  let resultState = { state: '', data: {} };
  const url = `${BASE_BACKEND_URL}/stock/pexels/search_videos/?context=${keyword}&page=${page}&orientation=${orientation}&locale=${locale}`;

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(function (response) {
      resultState = { state: 'success', data: response.data }
    }).catch(function (error) {
      resultState = { state: 'error', message: error.message }
      window.toastr.error(error.message);
    })
  } else {
    resultState = { state: 'success', data: { Code: "Success" } }
  }

  return resultState;
}


export const createStock = async () => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  // let mimetype = "video/mp4"
  // if(stockdata.video_preview_url) {
  //   const extension = stockdata.video_preview_url.splite(".").reverse()[0];
  //   const mtype = mime.lookup(`.${extension}`);
  //   if(mtype) {
  //     mimetype = mtype;
  //   }
  // }
  const data = {
    user: user_data,
    // manifest: {
    //   title: stockdata.title,
    //   video_preview_url: stockdata.video_preview_url,
    //   mime_type: mimetype
    // }
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/stock/`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}


export const updateStock = async (stockUUID, stockData) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let data = {
    user: user_data,
    manifest: {
      title: stockData.title,
      video_preview_url: stockData.video_preview_url,
      mime_type: "video/mp4"
    }
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'post',
      url: BASE_BACKEND_URL + `/stock/${stockUUID}/`,
      data: data,
      headers: { 'Content-Type': 'application/json' },
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}


export const getStock = async (stockUUID) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/stock/${stockUUID}`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const getPexelsStock = async (contentId) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/stock/pexels/get_video/?content_id=${contentId}`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const getAssetData = async (uuid, projectUUID) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  let url = `${BASE_BACKEND_URL}/asset/view/${uuid}/`;
  if(!!projectUUID) {
    url = `${BASE_BACKEND_URL}/project/${projectUUID}/asset/${uuid}`
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const createTextToSpeech = async (type) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/texttospeech/vendor/${type}/`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const setUserlanguage = async (langCode) => {
  const url = BASE_BACKEND_URL + `/user/set_language/`;
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  if(user_data != 'AnonymousUser'){
    user_data.locale = langCode;
    localStorage.setItem('userLocale', langCode);
  }else{
    console.log('animouse', langCode);
    localStorage.setItem('userLocale', langCode);
  }
  
  let data = {
    user: user_data,
  };
  await axios({
    method: 'post',
    url: url,
    data: data,
    headers: { 'Content-Type': 'application/json' },
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data['message'] = err.message;
  })
}

export const updateTextToSpeech = async (type, speechUUID, config, text) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let data = {
    user: user_data,
    uuid: speechUUID,
    config,
    text
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'post',
      url: BASE_BACKEND_URL + `/texttospeech/vendor/${type}/${speechUUID}/`,
      data: data,
      headers: { 'Content-Type': 'application/json' },
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const getCoupon = async (code) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = { user: user_data };
  await axios({
    method: 'get',
    url: BASE_BACKEND_URL + `/payment/coupon?code=${code}`,
    data,
    withCredentials: true
  }).then(res => {
    resultState.state = 'success';
    resultState.data = res.data;
  }).catch((err) => {
    resultState.state = 'error';
    resultState.data['message'] = err.message;
  })

  return resultState;
}

export const getCustomerStripeInformation = async () => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/payment/customer_stripe_information`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const addPaymentMethod = async (card) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data, card };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'post',
      url: `${BASE_BACKEND_URL}/payment/stripe_add_payment_method`,
      data: customer_data,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {};
  }

  return resultState;
}


export const deletePaymentMethod = async (id) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data, id };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'post',
      url: `${BASE_BACKEND_URL}/payment/delete_payment_method`,
      data: customer_data,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {};
  }

  return resultState;
}

export const getSubscriptionInfo = async () => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/payment/get_subscription_info`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}

export const getDefaultPaymentMethod = async () => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  const data = {
    user: user_data,
  }

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'get',
      url: BASE_BACKEND_URL + `/payment/get_default_payment_method`,
      data,
      withCredentials: true
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {
    };
  }

  return resultState;
}


export const setDefaultPaymentMethod = async (id) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data, id };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'post',
      url: `${BASE_BACKEND_URL}/payment/set_default_payment_method`,
      data: customer_data,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {};
  }

  return resultState;
}

export const cancelSubscription = async (id) => {
  let resultState = { state: '', data: {} };
  let user_data = getAccessToken();
  let customer_data = { user: user_data, id };

  if (SERVER_STATUS !== 'localhost') {
    await axios({
      method: 'post',
      url: `${BASE_BACKEND_URL}/payment/stripe_cancel_subscription`,
      data: customer_data,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    }).then(res => {
      resultState.state = 'success';
      resultState.data = res.data;
    }).catch((err) => {
      resultState.state = 'error';
      resultState.data['message'] = err.message;
    })
  } else {
    resultState.state = 'success';
    resultState.data = {};
  }

  return resultState;
}