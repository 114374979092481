export const editorLangDeStrs = {
    welcomNewProject : 'Willkommen bei Ihrem neuen Projekt',
    pls_add_media: 'Bitte fügen Sie Medien zu Ihrem Projekt hinzu',
    add_video: 'Video hinzufügen',
    add_audio: 'Audio hinzufügen',
    add_image: 'Bild hinzufügen',
    add_webcam: 'Webcam-Aufnahme',
    add_screen: 'Bildschirmaufnahme',
    add_webcam_screen: 'Webcam & Bildschirmaufnahme',
    capture_screen_camera: 'Erfassen Sie Ihren Bildschirm & Kamera!',
    video_captureDevice: 'Videofanggerät',
    resolution: 'Auflösung',
    audio_captureDevice: 'Audiofanggerät',
    resize_video: 'Video Größe ändern',
    rotate_video: 'Video drehen',
    loop_video: 'Video schleifen',
    joinmarge_video: 'Video hinzufügen/zusammenführen',
    cut_video: 'Video schneiden',
    crop_video: 'Video zuschneiden',
    translate_video: 'Video übersetzen',
    transcripbe_video: 'Video transkribieren',
    auto_subtitles: 'Automatische Untertitel',
    add_subtitle_video: 'Untertitel zum Video hinzufügen',
    compress_video: 'Video komprimieren',
    convert_video: 'Video konvertieren',
    project_not_accessible: 'Projekt ist nicht zugänglich',
    leave: 'Verlassen',
    cancel: 'Abbrechen',
    okay: 'OK',
    stop: 'Stoppen',
    record: 'Aufnehmen',
    ongoing_upload_project_str: 'Es läuft gerade ein Upload zu diesem Projekt.',
    leaving_page_sure: 'Sie verlassen diese Seite, sind Sie sicher?',
    uploading_files: 'Dateien hochladen',
    record_screen_and_upload: 'Nehmen Sie Ihren Bildschirm auf und laden Sie hoch.',
    show_defaul_sharingMenu: 'Standardfreigabemenü anzeigen',
    prefer_share_browser: 'Bevorzugen, einen Browser-Tab zu teilen',
    prefer_share_window: 'Bevorzugen, ein Fenster zu teilen',
    prefer_share_entireScreen: 'Bevorzugen, den ganzen Bildschirm zu teilen',
    record_webcam_and_upload: 'Nehmen Sie Ihre Webcam auf und laden Sie hoch.',
    sign_signup_to_uploadVideos: 'Bitte melden Sie sich an oder registrieren Sie sich, um Videos hochzuladen',
    sizelimir_or_timelimit: 'größer als 250 MB oder länger als 5 Minuten',
    screen_capture_preferences: 'Bildschirmaufnahme-Einstellungen',
    is_uploading: 'Wird hochgeladen',
    monthly: 'Monatlich',
    annually: 'Jährlich',
    most_popular: 'Am beliebtesten',
    export: 'Exportieren',
    bitrate: 'Bitrate',
    frame_rate_fps: 'Bildrate (FPS)',
    produce: 'Produzieren',
    setup: 'Einrichten',
    template: 'Vorlage',
    templates: 'Vorlagen',
    media: 'Medien',
    stock: 'Lager',
    translations: 'Übersetzungen',
    subtitles: 'Untertitel',
    text_to_speech: 'Text zu Sprache',
    transitions: 'Übergänge',
    text: 'Text',
    emojis: 'Emojis',
    filters: 'Filter',
    draw: 'Zeichnen',
    edit_subtitle: 'Untertitel bearbeiten',
    add_new_subtitle: 'neuen Untertitel hinzufügen',
    select_brush_stock_start_draw: 'Wählen Sie einen Pinselstrich, um mit dem Zeichnen zu beginnen',
    brush_stroke: 'Pinselstrich',
    round: 'Rund',
    sliced: 'Geschnitten',
    color: 'Farbe',
    opacity: 'Deckkraft',
    size: 'Größe',
    duration: 'Dauer',
    add_new_line: 'Neue Zeile hinzufügen',
    all_types: 'Alle Typen',
    load_more: 'Mehr laden',
    upload_video: 'Video hochladen',
    upload_audio: 'Audio hochladen',
    upload_image: 'Bild hochladen',
    create_webcam_recording: 'Webcam-Aufnahme erstellen',
    create_screen_capture_recording: 'Bildschirmaufnahme erstellen',
    create_webcam_screen_recording: 'Webcam & Bildschirmaufnahme erstellen',
    project_asset: 'Projektvermögen',
    you_want_add_translations: 'Wie möchten Sie Übersetzungen hinzufügen?',
    uploader: 'Uploader',
    auto: 'AUTO',
    auto_translations: 'Automatische Übersetzungen',
    automatically_add_transllations_video: 'Automatisch Übersetzungen zum Video hinzufügen',
    wait_moments_subtitles_being: 'Warten Sie einen Moment, während der Untertitel vorbereitet wird',
    select_language_detect: 'Sprache zur Erkennung auswählen',
    disable_translate: 'Übersetzen deaktivieren',
    select_language_select: 'Sprache zum Übersetzen auswählen',
    transition_duration: 'Übergangsdauer',
    coming_soon: 'Demnächst',
    language: 'Sprache',
    speaking_rate_defaul: 'Sprechgeschwindigkeit (Standard 1)',
    pitch_defaul: 'Tonhöhe (Standard 0)',
    volume_gain_default: 'Lautstärkeerhöhung (Standard 0)',
    create_text_speech: 'Text zu Sprache erstellen',
    add_text: 'Text hinzufügen',
    text: 'Text',
    start: 'Start',
    end: 'Ende',
    text_style: 'Textstil',
    background_color: 'Hintergrundfarbe',
    add_new_text_field: 'Neues Textfeld hinzufügen',
    how_add_subtitles: 'Wie möchten Sie Untertitel hinzufügen',
    automatically_subtitles_video: 'Automatisch Untertitel zum Video hinzufügen',
    text_color: 'Textfarbe',
    preview_text: 'Textvorschau',
    search: 'Suchen',
    orientation: 'Orientierung',
    next: 'Weiter',
    previous: 'Vorherige',
    locale: 'Standort',
    landscape: 'Querformat',
    portrait: 'Hochformat',
    square: 'Quadratisch',
    time: 'Zeit',
    background: 'Hintergrund',
    project_setting: 'Projekteinstellungen',
    use_template_project: 'Als Vorlageprojekt verwenden',
    visibility: 'Sichtbarkeit',
    category: 'Kategorie',
    capture_thumbnail: 'Miniaturansicht erfassen',
    brand_kit: 'Marken-Kit',
    video_placeholder: 'Video-Platzhalter2.mp4',
    replace_video: 'Video ersetzen',
    transformations: 'Transformationen',
    fill_canvas: 'Die Leinwand füllen',
    fit_canvas: 'Auf die Leinwand anpassen',
    round_corners: 'Abgerundete Ecken',
    audio: 'Audio',
    detach_audio: 'Audio trennen',
    cleanAudio: 'CleanAudio',
    upgrade_basic_unlock: 'Auf BASIC upgraden, um freizuschalten',
    remove_background_noise_enhance_audio_qty: 'Hintergrundgeräusche entfernen und Audioqualität verbessern',
    color_correction: 'Farbkorrektur',
    reset: 'Zurücksetzen',
    exposure: 'Belichtung',
    brightness: 'Helligkeit',
    contract: 'Kontrast',
    saturation: 'Sättigung',
    sharpen: 'Schärfen',
    blur: 'Weichzeichnen',
    noise: 'Rauschen',
    trending: 'TRENDING',
    custom: 'BENUTZERDEFINIERT',
    portrait: 'HOCHFORMAT',
    landscape: 'QUERFORMAT',
    settings: 'Einstellungen',
    magic_draw: 'Magisches Zeichnen',
    tools: 'Werkzeuge',
    brush_size: 'Pinselgröße',
    save: 'Speichern',
    clear: 'Löschen',
    view_all: 'Alle anzeigen',
    save_your_project_later: 'Speichern Sie Ihr Projekt für später',
    sign_in: 'anmelden',
    sign_up: 'registrieren',
    sign_up_home: 'Registrieren',
    redo: 'Wiederholen',
    undo: 'Rückgängig machen',
    collaborate: 'Kooperieren',
    enter_email_share_project: 'E-Mail eingeben, um Projekt zu teilen',
    share_project: 'Projekt teilen',
    create_new_workspace: 'Neuen Arbeitsbereich erstellen',
    audio_noise_remove: 'Entfernung von Audiorauschen',
    media_upload: 'Medien-Upload',
    draw_color: 'Farbe zeichnen',
    tsl_none: 'keiner',
    tsl_fade: 'Verblassen',
    tsl_book_flip: 'Buch-Flip',
    tsl_disolve: 'Sich auflösen',
    tsl_doorway: 'Tür',
    tsl_blur: 'Verwischen',
    tsl_push: 'Drücken',
    tsl_slide: 'Gleiten',
    tsl_wipe: 'Wischen',
    tsl_zoom_in: 'Hineinzoomen',
    tsl_zoom_out: 'Rauszoomen',
    flt_None: 'Keiner',
    flt_Darker: 'Dunkler',
    flt_BlacknWhite: 'Schwarz und Weiß',
    flt_Daphne: 'Seidelbast',
    flt_Penelope: 'Penelope',
    flt_Phoebe: 'Phoebe',
    flt_Thalia: 'Thalia',
    flt_Chloe: 'Chloe',
    flt_Hermione: 'Hermine',
    flt_Hera: 'Hera',
    flt_Rhea: 'Rhea',
    flt_Donna: 'Donna',
    flt_Martina: 'Martina',
    flt_Gabriella: 'Gabriella',
    flt_Elisabetta: 'Elisabetta',
    flt_Isabella: 'Isabella',
    flt_Luna: 'Luna',
    flt_Viola: 'Viola',
    flt_Zita: 'Zita',
    flt_Donata: 'Donata',
    flt_Maddalena: 'Maddalena',
    flt_Tecla: 'Tecla',
    flt_Narcisa: 'Narcisa',
    emj_Smileys: 'Smileys',
    emj_Animals: 'Tiere',
    emj_People: 'Menschen',
    emj_Jobs: 'Arbeitsplätze',
    emj_Sports: 'Sport',
    emj_Flags: 'Flaggen',
    emj_Signs: 'Zeichen',
    emj_Nature: 'Natur',
    emj_Travel: 'Reisen',
    emj_Letters: 'Briefe',
    emj_Misc: 'Sonstiges',
    create_empty_project_templates: 'Erstellen Sie ein leeres Projekt aus Vorlagen',
    days_ago: 'Vor Tagen',
    create_workspace: 'Arbeitsbereich erstellen',
    workspace: 'Arbeitsbereiche',
    delete_workspace: 'Arbeitsbereich löschen',
    transfer_owner: 'Eigentümer übertragen',
    can_create_new_workspace: 'Könnten Sie einen neuen Arbeitsbereich erstellen?',
    could_delete_workspace: 'Könnten Sie den Arbeitsbereich löschen?',
    available_subtitles_time: 'Verfügbare Untertitelzeit',
    available_storage_space: 'Verfügbarer Speicherplatz',
    available_text_to_speech: 'Verfügbare Text-zu-Sprache',
    other_workspaces: 'Andere Arbeitsbereiche',
    members: 'Mitglieder',
    add_member: 'Mitglied hinzufügen',
    remove_member: 'Mitglied entfernen',
    change_member_level: 'Mitgliedsebene ändern',
    owner: 'Eigentümer',
    admin: 'Administratorin',
    member: 'Member',
    read_only: 'Schreibgeschützt',
    invitation_list: 'Einladungsliste',
    duplicate: 'Duplikat',
    splite: 'Teilt',
    remove: 'entfernen',
    layer: 'Schicht',
    volume: 'Volumen',
    deleterow: 'Zeile löschen',
    fullscreen: 'Ganzer Bildschirm',
    editsubtitle: 'Untertitel bearbeiten',
    fit: 'Fit',
    green_screen: 'Grüner Bildschirm',
    profile: 'Profil',
    pricing: 'Preisgestaltung',
    login: 'Anmeldung',
    logout: 'Ausloggen',
    get_started_free: "Legen Sie los–es ist kostenlos",
    dashboard: 'Armaturenbrett',
    projects: 'Projekte',
    menu_Features: 'Merkmale',
    menu_Tools: 'Werkzeuge',
    menu_VideoEditor: 'Videobearbeiter',
    menu_AIVideoEditor: 'KI-Videobearbeiter',
    menu_AddImgToVideo: 'Bild zum Video hinzufügen',
    menu_AddMusicToVideo: 'Musik zum Video hinzufügen',
    menu_AddTextToVideo: 'Text zum Video hinzufügen',
    menu_FilterVideo: 'Video filtern',
    menu_AddTransitionstoVideo: 'Übergänge zum Video hinzufügen',
    menu_AddFreeStockVideos:'Fügen Sie kostenlose Stockvideos hinzu',
    menu_SubtitlesTranscription: 'Untertitel und Transkription',
    menu_AddSubtitlesToVideo: 'Untertitel zum Video hinzufügen',
    menu_AudioToText: 'Audio zu Text',
    menu_AutoSubtitles: 'Automatische Untertitel',
    menu_TranscribeVideo: 'Video transkribieren',
    menu_TranslateVideo: 'Video übersetzen',
    menu_Toolkit: 'Toolkit',
    menu_Crop: 'Ernte',
    menu_Cut: 'Schneiden',
    menu_JoinMerge: 'Beitreten/Zusammenführen',
    menu_LoopVideo: 'Loop-Video',
    menu_Resize: 'Größe ändern',
    menu_Rotate: 'Drehen',
    menu_More: 'Mehr',
    menu_VideoCompressor: 'Videokompressor',
    menu_VideoConverter: 'Videokonverter',
    menu_AIGenerated: 'KI-generiert',
    menu_AIGeneratedImages: 'KI-generierte Bilder',
    menu_AIGeneratedVideos: 'KI-generierte Videos',
    menu_AIGeneratedVoices: 'KI-generierte Stimmen',
    menu_Blog: 'Blog',
    menu_VIDEOOBlog: 'VIDEOO Blog',
    menu_VIDEOOHowTo: 'VIDEO Anleitung',
    menu_VIDEOOCareers: 'VIDEOO-Karriere',
    online_ai_video_editor : 'Online-KI-Videobearbeiter',
    online_could_video_editor: 'Online-Cloud-basierter Video-Editor',
    home_free_trial_detail: '7 Tage kostenlos testen, jederzeit kündbar',
    home_create_button_detail: 'Videoerstellung mit einem Klick mit automatisierten Untertiteln, Übersetzung und mehr',
    create_now: 'Armaturenbrett',
    home_templates_start: 'Entdecken Sie eine Reihe von Vorlagen, um loszulegen',
    try_now_required: 'Probieren Sie es jetzt aus, kein Konto erforderlich',
    online_collaborative_video_editing: 'Kollaborative Online-Videobearbeitung',
    online_collaborative_video_editing_detail: 'Tauchen Sie mit videoo.io in die Welt der cloudbasierten Online-Videobearbeitung ein. Unsere Plattform revolutioniert die Art und Weise, wie Sie bearbeiten, und bietet Online-Funktionen zur kollaborativen Videobearbeitung und einen cloudbasierten Arbeitsbereich für die Videobearbeitung.',
    auto_subtitles_translations: 'Automatische Untertitel und Übersetzungen',
    auto_subtitles_translations_detail: "Verbessern Sie Ihre Videos mit unserer Funktion „Automatische Untertitel und Übersetzungen“, einem Teil unserer professionellen Online-Videobearbeitungssoftware. videoo.io wurde als Videobearbeitungssoftware für jedermann konzipiert und macht es einfach, Inhalte zu erstellen, die zugänglich und eingängig sind.",
    ai_base_text_speech: 'KI-basierter Text-to-Speech',
    ai_base_text_speech_detail: 'Unsere KI-basierten Text-to-Speech-Stimmen eignen sich perfekt für Content-Ersteller und verkörpern das Konzept der Videobearbeitung neu erfunden für Youtuber und Influencer. Mit videoo.io verwandeln Sie Ihre Skripte in ansprechende Voiceovers, verleihen Ihren Videos eine professionelle Note und optimieren Ihren Produktionsprozess.',
    free_user_stock_video: 'Kostenlose Nutzung von Stockvideos',
    free_user_stock_video_detail: 'Greifen Sie mit videoo.io auf eine umfangreiche Sammlung kostenloser Stockvideos zu, die unsere Online-Funktionen zur 4K-Videobearbeitung ergänzen. Unsere Plattform ist eine 4K-Videobearbeitungssoftware für jedermann und stellt sicher, dass Ihre Projekte durch qualitativ hochwertiges Filmmaterial hervorstechen, sodass professionelle Bearbeitung für alle Benutzer zugänglich ist.',
    share: 'Aktie',
    unlisted_video: 'Nicht gelistetes Video',
    your_video_about_ready: 'Ihr Video ist bald fertig',
    hours: 'Std',
    minutes: 'Protokoll',
    seconds: 'Sekunden',
    want_free_stuff: 'Willst du ein paar kostenlose Sachen?',
    record_screen_webcam_both: 'Zeichnen Sie Ihren Bildschirm, Ihre Webcam oder beides auf',
    add_subtitle_your_video: 'Fügen Sie Untertitel zu Ihren Videos hinzu',
    create_your_team_workspace: 'Erstellen Sie Ihren Teamarbeitsbereich',
    collaborate_videos: 'Arbeiten Sie gemeinsam an Videos',
    generate_transcript: 'Erstellen Sie ein Transkript',
    singup_get_all_this_more: 'Melden Sie sich (kostenlos) an, um all das und noch mehr zu erhalten!',
    downloading: 'wird heruntergeladen',
    download: 'Herunterladen',
    reedit_video: 'Video erneut bearbeiten',
    remove_watermark: 'Wasserzeichen entfernen',
    already_have_account: 'Sie haben bereits ein Konto?',
    link_copied: 'Link kopiert',
    invitation_list: 'Einladungsliste',
    remove_invitation: 'Einladung entfernen',
    change_workspace: 'Arbeitsbereich ändern',
    you_login_manage_your_profile: 'Sie müssen sich anmelden, um Ihr Profil zu verwalten',
    you_dont_have_content_here: "Sie haben hier keinen Inhalt.",
    name: 'Name',
    status: 'Status',
    data_modified: 'Datum geändert',
    all_workspaces: 'Alle Arbeitsbereiche',
    delete_project: 'Projekt löschen',
    duplicate_project: 'Doppeltes Projekt',
    draft: 'Entwurf',
    edit: 'Bearbeiten',
    surname: 'Nachname',
    email: 'Email',
    plan: 'Planen',
    market_emails: 'Marketing-E-Mails',
    editor_notification: 'Editor-Benachrichtigungen',
    storage: 'Lagerung',
    subtitle: 'Untertitel',
    delete_account: 'Konto löschen',
    this_action_irreversibe: 'Diese Aktion ist irreversible',
    current_plan: 'DERZEITIGER PLAN',
    cancel_subscription: 'Abonnement beenden',
    payment_method: 'ZAHLUNGSARTEN',
    expires: 'Läuft ab',
    make_default: 'Machen Standard',
    delete: 'Löschen',
    add_payment_method: 'Zahlungsmethode hinzufügen',
    billing_information: 'ABRECHNUNGSDATEN',
    invoice_history: 'RECHNUNGSVERLAUF',
    payment: 'Zahlung',
    preferences: 'Präferenzen',
    save_up_to: 'Sparen Sie bis zu',
    your_plan: 'Dein Plan',
    inactive: 'Inaktiv',
    contact_sales: 'Kontaktieren Sie den Vertrieb',
    need_additional_features: 'Benötigen Sie zusätzliche Funktionen? Fragen Sie uns nach unserem Enterprise-Plan'
};