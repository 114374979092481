export const editorLangEnStrs = {
    welcomNewProject : 'Welcome to your new project',
    pls_add_media:'Please add media to your project',
    add_video:'Add Video',
    add_audio:'Add Audio',
    add_image:'Add Image',
    add_webcam:'WebCam Capture',
    add_screen:'Screen Capture',
    add_webcam_screen:'WebCam & Screen Capture',
    capture_screen_camera:'Capture your screen & camera !',
    video_captureDevice:'Video Capture Device',
    resolution: 'Resolution',
    audio_captureDevice: 'Audio Capture Device',
    resize_video: 'Resize Video',
    rotate_video: 'Rotate Video',
    loop_video: 'Loop Video',
    joinmarge_video: 'Add Join/Merge Video',
    cut_video: 'Cut Video',
    crop_video: 'Crop Video',
    translate_video: 'Translate Video',
    transcripbe_video: 'Transcribe Video',
    auto_subtitles: 'Auto Subtitles',
    add_subtitle_video: 'Add Subtitle to Video',
    compress_video: 'Compress Video',
    convert_video: 'Convert Video',
    project_not_accessible: 'Project is not accessible',
    leave: 'Leave',
    cancel: 'Cancel',
    okay: 'Ok',
    stop: 'Stop',
    record: 'Record',
    ongoing_upload_project_str: 'There is an ongoing upload to this project.',
    leaving_page_sure: 'You are leaving this page, are you sure?',
    uploading_files: 'Uploading Files',
    record_screen_and_upload: 'Record your screen and upload.',
    show_defaul_sharingMenu: 'Show default sharing MenuItems',
    prefer_share_browser: 'Prefer to share a browser tab',
    prefer_share_window: 'Prefer to share a window',
    prefer_share_entireScreen: 'Prefer to share an entire screen',
    record_webcam_and_upload: 'Record your web camera and upload.',
    sign_signup_to_uploadVideos: 'Please sign in or sign up to upload videos',
    sizelimir_or_timelimit: 'larger than 250 Mbytes or longer than 5 minutes',
    screen_capture_preferences: 'Screen Capture Preferences',
    is_uploading: 'Is Uploading',
    monthly: 'Monthly',
    annually: 'Annually',
    most_popular: 'Most Popular',
    export: 'Export',
    bitrate: 'Bitrate',
    frame_rate_fps: 'Frame Rate (FPS)',
    produce: 'Produce',
    setup: 'Setup',
    template: 'Template',
    templates: 'Templates',
    media: 'Media',
    stock: 'Stock',
    translations: 'Translations',
    subtitles: 'Subtitles',
    text_to_speech: 'Text To Speech',
    transitions: 'Transitions',
    text: 'Text',
    emojis: 'Emojis',
    filters: 'Filters',
    draw: 'Draw',
    edit_subtitle: 'Edit Subtitle',
    add_new_subtitle: 'add new subtitle',
    select_brush_stock_start_draw: 'Select a brush stroke to start drawing',
    brush_stroke: 'Brush Stroke',
    round: 'Round',
    sliced: 'Sliced',
    color: 'Color',
    opacity: 'Opacity',
    size: 'Size',
    duration: 'Duration',
    add_new_line: 'Add New Line',
    all_types: 'All Types',
    load_more: 'Load More',
    upload_video: 'Upload Video',
    upload_audio: 'Upload Audio',
    upload_image: 'Upload Image',
    create_webcam_recording: 'Create Webcam Recording',
    create_screen_capture_recording: 'Create Screen Capture Recording',
    create_webcam_screen_recording: 'Create Webcam & Screen Recording',
    project_asset: 'Project Asset',
    you_want_add_translations: 'How do you want to add translations?',
    uploader: 'Uploader',
    auto: 'AUTO',
    auto_translations: 'Auto Translations',
    automatically_add_transllations_video: 'Automatically add translations to video',
    wait_moments_subtitles_being: 'Wait for a few moments while subtitle is being prepared',
    select_language_detect: 'Select language to detect',
    disable_translate: 'Disable Translate',
    select_language_select: 'Select Language to translate into',
    transition_duration: 'Transition Duration',
    coming_soon: 'Coming Soon',
    language: 'Language',
    speaking_rate_defaul: 'Speaking Rate (default 1)',
    pitch_defaul: 'Pitch (default 0)',
    volume_gain_default: 'Volume Gain (default 0)',
    create_text_speech: 'Create Text To Speech',
    add_text: 'Add Text',
    text: 'Text',
    start: 'Start',
    end: 'End',
    text_style: 'Text Style',
    background_color: 'Background Colour',
    add_new_text_field: 'Add New Text Field',
    how_add_subtitles: 'How do you want to add subtitles',
    automatically_subtitles_video: 'Automatically add subtitles to video',
    text_color: 'Text Colour',
    preview_text: 'Preview Text',
    search: 'Search',
    orientation: 'Orientation',
    next: 'Next',
    previous: 'Previous',
    locale: 'Locale',
    landscape: 'Landscape',
    portrait: 'Portrait',
    square: 'Square',
    time: 'Time',
    background: 'Background',
    project_setting: 'Project Settings',
    use_template_project: 'Use as template project',
    visibility: 'Visibility',
    category: 'Category',
    capture_thumbnail: 'Capture Thumbnail',
    brand_kit: 'Brand Kit',
    video_placeholder: 'Video Placeholder2.mp4',
    replace_video: 'Replace Video',
    transformations: 'Transformations',
    fill_canvas: 'Fill the canvas',
    fit_canvas: 'Fit the canvas',
    round_corners: 'Round Corners',
    audio: 'Audio',
    detach_audio: 'Detach Audio',
    cleanAudio: 'CleanAudio',
    upgrade_basic_unlock: 'Upgrade to BASIC to unlock',
    remove_background_noise_enhance_audio_qty: 'Remove background noise and enhance audio quality',
    color_correction: 'Color Correction',
    reset: 'Reset',
    exposure: 'Exposure',
    brightness: 'Brightness',
    contract: 'Contrast',
    saturation: 'Saturation',
    sharpen: 'Sharpen',
    blur: 'Blur',
    noise: 'Noise',
    trending: 'TRENDING',
    custom: 'CUSTOM',
    portrait: 'PORTRAIT',
    landscape: 'LANDSCAPE',
    settings: 'Settings',
    magic_draw: 'Magic Draw',
    tools: 'Tools',
    brush_size: 'Brush Size',
    save: 'Save',
    clear: 'Clear',
    view_all: 'View All',
    save_your_project_later: 'Save your project for later',
    sign_in: 'sign in',
    sign_up: 'sign up',
    sign_up_home: 'Signup',
    redo: 'REDO',
    undo: 'UNDO',
    collaborate: 'COLLABORATE',
    enter_email_share_project: 'Enter email to share project',
    share_project: 'Share Project',
    create_new_workspace: 'Create new Workspace',
    audio_noise_remove: 'Audio Noise Removal',
    media_upload: 'Media Upload',
    draw_color: 'Draw Color',
    tsl_none: 'none',
    tsl_fade: 'Fade',
    tsl_book_flip: 'Book Flip',
    tsl_disolve: 'Disolve',
    tsl_doorway: 'Doorway',
    tsl_blur: 'Blur',
    tsl_push: 'Push',
    tsl_slide: 'Slide',
    tsl_wipe: 'Wipe',
    tsl_zoom_in: 'Zoom In',
    tsl_zoom_out: 'Zoom Out',
    flt_None: 'None',
    flt_Darker: 'Darker',
    flt_BlacknWhite: 'BlacknWhite',
    flt_Daphne: 'Daphne',
    flt_Penelope: 'Penelope',
    flt_Phoebe: 'Phoebe',
    flt_Thalia: 'Thalia',
    flt_Chloe: 'Chloe',
    flt_Hermione: 'Hermione',
    flt_Hera: 'Hera',
    flt_Rhea: 'Rhea',
    flt_Donna: 'Donna',
    flt_Martina: 'Martina',
    flt_Gabriella: 'Gabriella',
    flt_Elisabetta: 'Elisabetta',
    flt_Isabella: 'Isabella',
    flt_Luna: 'Luna',
    flt_Viola: 'Viola',
    flt_Zita: 'Zita',
    flt_Donata: 'Donata',
    flt_Maddalena: 'Maddalena',
    flt_Tecla: 'Tecla',
    flt_Narcisa: 'Narcisa',
    emj_Smileys: 'Smileys',
    emj_Animals: 'Animals',
    emj_People: 'People',
    emj_Jobs: 'Jobs',
    emj_Sports: 'Sports',
    emj_Flags: 'Flags',
    emj_Signs: 'Signs',
    emj_Nature: 'Nature',
    emj_Travel: 'Travel',
    emj_Letters: 'Letters',
    emj_Misc: 'Misc',
    create_empty_project_templates: 'Create an Empty Project From Templates',
    days_ago: 'days ago',
    create_workspace: 'Create Workspace',
    workspace: 'Workspaces',
    delete_workspace: 'Delete Workspace',
    transfer_owner: 'Transfer Owner',
    can_create_new_workspace: 'Could you create new workspace?',
    could_delete_workspace: 'Could you delete workspace?',
    available_subtitles_time: 'Available Subtitles Time',
    available_storage_space: 'Available Storage Space',
    available_text_to_speech: 'Available Text To Speech',
    other_workspaces: 'Other workspaces',
    members: 'Members',
    add_member: 'Add Member',
    remove_member: 'Remove Member',
    change_member_level: 'Change Member Level',
    owner: 'Owner',
    admin: 'Admin',
    member: 'Member',
    read_only: 'Read Only',
    duplicate: 'Duplicate',
    splite: 'Split',
    remove: 'remove',
    layer: 'Layer',
    volume: 'Volume',
    disable: 'Disable',
    deleterow: 'DeleteRow',
    fullscreen: 'Fullscreen',
    editsubtitle: 'EditSubtitle',
    fit: 'Fit',
    green_screen: 'Green Screen',
    profile: 'Profile',
    pricing: 'Pricing',
    login: 'Login',
    logout: 'Log out',
    get_started_free: "Get Started-it's free",
    dashboard: 'Dashboard',
    projects: 'Projects',
    menu_Features: 'Features',
    menu_Tools: 'Tools',
    menu_Convert: 'Video Converter',
    menu_Compressor: 'Video Compressor',
    menu_AIVideoEditor: 'Video Editor',
    menu_VideoEditor: 'Video Editor',
    menu_AddImgToVideo: 'Add Image To Video',
    menu_AddMusicToVideo: 'Add Music To Video',
    menu_AddTextToVideo: 'Add Text To Video',
    menu_FilterVideo: 'Filter Video',
    menu_AddTransitionstoVideo: 'Add Transitions to Video',
    menu_AddFreeStockVideos:'Add Free Stock Videos',
    menu_SubtitlesTranscription: 'Subtitles & Transcription',
    menu_AddSubtitlesToVideo: 'Add Subtitles To Video',
    menu_AudioToText: 'Audio To Text',
    menu_AutoSubtitles: 'Auto Subtitles',
    menu_TranscribeVideo: 'Transcribe Video',
    menu_TranslateVideo: 'Translate Video',
    menu_Toolkit: 'Toolkit',
    menu_Crop: 'Crop',
    menu_Cut: 'Cut',
    menu_JoinMerge: 'Join/Merge',
    menu_LoopVideo: 'Loop Video',
    menu_Resize: 'Resize',
    menu_Rotate: 'Rotate',
    menu_More: 'More',
    menu_VideoCompressor: 'Video Compressor',
    menu_VideoConverter: 'Video Converter',
    menu_AIGenerated: 'AI-Generated',
    menu_AIVideoSummary: 'AI Video Summarization',
    menu_AIGeneratedImages: 'AI-Generated Images',
    menu_AIGeneratedVideos: 'AI-Generated Videos',
    menu_AIGeneratedVoices: 'AI-Generated Voices',
    menu_Blog: 'Blog',
    menu_VIDEOOBlog: 'VIDEOO Blog',
    menu_VIDEOOHowTo: 'VIDEOO How to',
    menu_VIDEOOCareers: 'VIDEOO Careers',
    online_ai_video_editor: 'Your Online AI Video Editor',
    online_could_video_editor: 'Online Cloud Based Video Editor',
    home_free_trial_detail: '7 Days Free Trial, Cancel Anytime',
    home_create_button_detail: 'One-click video creation with automated subtitles, translation, and more',
    create_now: 'Dashboard',
    home_templates_start: 'Explore a range of templates to get started',
    try_now_required: 'Try it now, no account required',
    online_collaborative_video_editing: 'Online Collaborative Video Editing',
    online_collaborative_video_editing_detail: 'Dive into the world of Online Cloud-Based Video Editing with videoo.io. Our platform revolutionizes the way you edit, offering Online Collaborative Video Editing capabilities and a Cloud-Based Video Editing Workspace.',
    auto_subtitles_translations: 'Auto Subtitles and Translations',
    auto_subtitles_translations_detail: "Enhance your videos with our Auto Subtitles and Translations feature, a part of our Professional Online Video Editing Software. Designed to be a Video Editing Software Tool for Everyone, videoo.io makes it simple to create content that's accessible and catchy.",
    ai_base_text_speech: 'AI based Text To Speech',
    ai_base_text_speech_detail: 'Our AI-based Text to Speech Voices are perfect for content creators, embodying the concept of Video Editing Reinvented for Youtubers and Influencers. With videoo.io, transform your scripts into engaging voiceovers, adding a professional touch to your videos and streamlining your production process.',
    free_user_stock_video: 'Free to use Stock Videos',
    free_user_stock_video_detail: 'Access a vast collection of Free to Use Stock Videos with videoo.io, complementing our 4K Video Editing Online capabilities. Our platform, being a 4K Video Editing Software for Everyone, ensures that your projects stand out with high-quality footage, making professional-grade editing accessible to all users.',
    share: 'Share',
    unlisted_video: 'Unlisted Video',
    your_video_about_ready: 'Your video is about to be ready',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    want_free_stuff: 'Want some free stuff?',
    record_screen_webcam_both: 'Record your screen, webcam, or both',
    add_subtitle_your_video: 'Add subtitles to your videos',
    create_your_team_workspace: 'Create your Team Workspace',
    collaborate_videos: 'Collaborate on videos',
    generate_transcript: 'Generate a transcript',
    singup_get_all_this_more: 'Sign up (for free) to get all this and more!',
    downloading: 'Downloading',
    download: 'Download',
    reedit_video: 'Re-edit video',
    remove_watermark: 'Remove watermark',
    already_have_account: 'Already have an account?',
    link_copied: 'Link Copied',
    invitation_list: 'Invitation List',
    remove_invitation: 'Remove Invitation',
    change_workspace: ' Change workspace',
    you_login_manage_your_profile: 'You need to login to manage your profile',
    you_dont_have_content_here: "You don't have any content here.",
    name: 'Name',
    status: 'Status',
    data_modified: 'Date Modified',
    all_workspaces: 'All Workspaces',
    delete_project: 'Delete Project',
    duplicate_project: 'Duplicate Project',
    draft: 'Draft',
    edit: 'Edit',
    surname: 'Surname',
    email: 'Email',
    plan: 'Plan',
    market_emails: 'Marketing Emails',
    editor_notification: 'Editor Notifications',
    storage: 'Storage',
    subtitle: 'Subtitle',
    delete_account: 'Delete Account',
    this_action_irreversibe: 'This action is irreversible',
    current_plan: 'CURRENT PLAN',
    cancel_subscription: 'Cancel Subscription',
    payment_method: 'PAYMENT METHODS',
    expires: 'Expires',
    make_default: 'Make Default',
    delete: 'Delete',
    add_payment_method: 'Add payment method',
    billing_information: 'BILLING INFORMATION',
    invoice_history: 'INVOICE HISTORY',
    payment: 'Payment',
    preferences: 'Preferences',
    save_up_to: 'Save Up To ',
    your_plan: 'Your Plan',
    inactive: 'Inactive',
    contact_sales: 'Contact Sales',
    need_additional_features: 'Need additional features? Ask us about our Enterprise plan'
};