export const editorLangPtStrs = {
    "welcomNewProject": "Bem-vindo ao seu novo projeto",
    "pls_add_media": "Por favor, adicione mídia ao seu projeto",
    "add_video": "Adicionar Vídeo",
    "add_audio": "Adicionar Áudio",
    "add_image": "Adicionar Imagem",
    "add_webcam": "Captura de Webcam",
    "add_screen": "Captura de Tela",
    "add_webcam_screen": "Captura de Webcam & Tela",
    "capture_screen_camera": "Capture sua tela & câmera!",
    "video_captureDevice": "Dispositivo de Captura de Vídeo",
    "resolution": "Resolução",
    "audio_captureDevice": "Dispositivo de Captura de Áudio",
    "resize_video": "Redimensionar Vídeo",
    "rotate_video": "Rotacionar Vídeo",
    "loop_video": "Loop de Vídeo",
    "joinmarge_video": "Adicionar Junção/Mesclagem de Vídeo",
    "cut_video": "Cortar Vídeo",
    "crop_video": "Recortar Vídeo",
    "translate_video": "Traduzir Vídeo",
    "transcripbe_video": "Transcrever Vídeo",
    "auto_subtitles": "Legendas Automáticas",
    "add_subtitle_video": "Adicionar Legenda ao Vídeo",
    "compress_video": "Comprimir Vídeo",
    "convert_video": "Converter Vídeo",
    "project_not_accessible": "Projeto não acessível",
    "leave": "Sair",
    "cancel": "Cancelar",
    "okay": "Ok",
    "stop": "Parar",
    "record": "Gravar",
    "ongoing_upload_project_str": "Há um carregamento em andamento para este projeto.",
    "leaving_page_sure": "Você está saindo desta página, tem certeza?",
    "uploading_files": "Carregando Arquivos",
    "record_screen_and_upload": "Grave sua tela e faça o upload.",
    "show_defaul_sharingMenu": "Mostrar itens de menu de compartilhamento padrão",
    "prefer_share_browser": "Preferir compartilhar uma aba do navegador",
    "prefer_share_window": "Preferir compartilhar uma janela",
    "prefer_share_entireScreen": "Preferir compartilhar a tela inteira",
    "record_webcam_and_upload": "Grave sua webcam e faça o upload.",
    "sign_signup_to_uploadVideos": "Por favor, faça login ou cadastre-se para fazer upload de vídeos",
    "sizelimir_or_timelimit": "maior que 250 Mbytes ou mais longo que 5 minutos",
    "screen_capture_preferences": "Preferências de Captura de Tela",
    "is_uploading": "Está Carregando",
    "monthly": "Mensal",
    "annually": "Anual",
    "most_popular": "Mais Popular",
    "export": "Exportar",
    "bitrate": "Taxa de Bits",
    "frame_rate_fps": "Taxa de Quadros (FPS)",
    "produce": "Produzir",
    "setup": "Configuração",
    "template": "Modelo",
    "templates": "Modelos",
    "media": "Mídia",
    "stock": "Estoque",
    "translations": "Traduções",
    "subtitles": "Legendas",
    "text_to_speech": "Texto para Fala",
    "transitions": "Transições",
    "text": "Texto",
    "emojis": "Emojis",
    "filters": "Filtros",
    "draw": "Desenhar",
    "edit_subtitle": "Editar Legenda",
    "add_new_subtitle": "adicionar nova legenda",
    "select_brush_stock_start_draw": "Selecione um pincel para começar a desenhar",
    "brush_stroke": "Pincelada",
    "round": "Redondo",
    "sliced": "Fatiado",
    "color": "Cor",
    "opacity": "Opacidade",
    "size": "Tamanho",
    "duration": "Duração",
    "add_new_line": "Adicionar Nova Linha",
    "all_types": "Todos os Tipos",
    "load_more": "Carregar Mais",
    "upload_video": "Fazer Upload de Vídeo",
    "upload_audio": "Fazer Upload de Áudio",
    "upload_image": "Fazer Upload de Imagem",
    "create_webcam_recording": "Criar Gravação de Webcam",
    "create_screen_capture_recording": "Criar Gravação de Captura de Tela",
    "create_webcam_screen_recording": "Criar Gravação de Webcam & Tela",
    "project_asset": "Ativo do Projeto",
    "you_want_add_translations": "Como você deseja adicionar traduções?",
    "uploader": "Uploader",
    "auto": "AUTO",
    "auto_translations": "Traduções Automáticas",
    "automatically_add_transllations_video": "Adicionar traduções automaticamente ao vídeo",
    "wait_moments_subtitles_being": "Aguarde alguns momentos enquanto a legenda está sendo preparada",
    "select_language_detect": "Selecione a língua para detectar",
    "disable_translate": "Desativar Tradução",
    "select_language_select": "Selecione a Língua para traduzir",
    "transition_duration": "Duração da Transição",
    "coming_soon": "Em Breve",
    "language": "Idioma",
    "speaking_rate_defaul": "Taxa de Fala (padrão 1)",
    "pitch_defaul": "Tom (padrão 0)",
    "volume_gain_default": "Ganho de Volume (padrão 0)",
    "create_text_speech": "Criar Texto para Fala",
    "add_text": "Adicionar Texto",
    "text": "Texto",
    "start": "Início",
    "end": "Fim",
    "text_style": "Estilo do Texto",
    "background_color": "Cor de Fundo",
    "add_new_text_field": "Adicionar Novo Campo de Texto",
    "how_add_subtitles": "Como você deseja adicionar legendas",
    "automatically_subtitles_video": "Adicionar legendas automaticamente ao vídeo",
    "text_color": "Cor do Texto",
    "preview_text": "Pré-visualizar Texto",
    "search": "Buscar",
    "orientation": "Orientação",
    "next": "Próximo",
    "previous": "Anterior",
    "locale": "Localidade",
    "landscape": "Paisagem",
    "portrait": "Retrato",
    "square": "Quadrado",
    "time": "Tempo",
    "background": "Fundo",
    "project_setting": "Configurações do Projeto",
    "use_template_project": "Usar como modelo de projeto",
    "visibility": "Visibilidade",
    "category": "Categoria",
    "capture_thumbnail": "Capturar Miniatura",
    "brand_kit": "Kit de Marca",
    "video_placeholder": "EspaçoReservadoParaVídeo2.mp4",
    "replace_video": "Substituir Vídeo",
    "transformations": "Transformações",
    "fill_canvas": "Preencher a tela",
    "fit_canvas": "Ajustar à tela",
    "round_corners": "Cantos Arredondados",
    "audio": "Áudio",
    "detach_audio": "Desanexar Áudio",
    "cleanAudio": "LimparÁudio",
    "upgrade_basic_unlock": "Upgrade para BASIC para desbloquear",
    "remove_background_noise_enhance_audio_qty": "Remover ruído de fundo e melhorar qualidade do áudio",
    "color_correction": "Correção de Cor",
    "reset": "Resetar",
    "exposure": "Exposição",
    "brightness": "Brilho",
    "contract": "Contraste",
    "saturation": "Saturação",
    "sharpen": "Afiar",
    "blur": "Desfocar",
    "noise": "Ruído",
    "trending": "TENDÊNCIAS",
    "custom": "PERSONALIZADO",
    "portrait": "RETRATO",
    "landscape": "PAISAGEM",
    "settings": "Configurações",
    "magic_draw": "Desenho Mágico",
    "tools": "Ferramentas",
    "brush_size": "Tamanho do Pincel",
    "save": "Salvar",
    "clear": "Limpar",
    "view_all": "Ver Tudo",
    "save_your_project_later": "Salve seu projeto para mais tarde",
    "sign_in": "entrar",
    "sign_up": "cadastrar-se",
    "sign_up_home": "Cadastre-se",
    "redo": "REFAZER",
    "undo": "DESFAZER",
    "collaborate": "COLABORAR",
    "enter_email_share_project": "Digite o email para compartilhar o projeto",
    "share_project": "Compartilhar Projeto",
    "create_new_workspace": "Criar novo Espaço de Trabalho",
    "audio_noise_remove": "Remoção de Ruído de Áudio",
    "media_upload": "Upload de Mídia",
    "draw_color": "Cor do Desenho",
    "tsl_none": "nenhum",
    "tsl_fade": "Desvanecer",
    "tsl_book_flip": "Virar Livro",
    "tsl_disolve": "Dissolver",
    "tsl_doorway": "Porta",
    "tsl_blur": "Borrar",
    "tsl_push": "Empurrar",
    "tsl_slide": "Deslizar",
    "tsl_wipe": "Limpar",
    "tsl_zoom_in": "Zoom In",
    "tsl_zoom_out": "Zoom Out",
    "flt_None": "Nenhum",
    "flt_Darker": "Mais Escuro",
    "flt_BlacknWhite": "Preto e Branco",
    "flt_Daphne": "Dafne",
    "flt_Penelope": "Penélope",
    "flt_Phoebe": "Febe",
    "flt_Thalia": "Tália",
    "flt_Chloe": "Cloe",
    "flt_Hermione": "Hermione",
    "flt_Hera": "Hera",
    "flt_Rhea": "Reia",
    "flt_Donna": "Donna",
    "flt_Martina": "Martina",
    "flt_Gabriella": "Gabriela",
    "flt_Elisabetta": "Elisabetta",
    "flt_Isabella": "Isabella",
    "flt_Luna": "Luna",
    "flt_Viola": "Viola",
    "flt_Zita": "Zita",
    "flt_Donata": "Donata",
    "flt_Maddalena": "Maddalena",
    "flt_Tecla": "Tecla",
    "flt_Narcisa": "Narcisa",
    "emj_Smileys": "Sorrisos",
    "emj_Animals": "Animais",
    "emj_People": "Pessoas",
    "emj_Jobs": "Trabalhos",
    "emj_Sports": "Esportes",
    "emj_Flags": "Bandeiras",
    "emj_Signs": "Sinais",
    "emj_Nature": "Natureza",
    "emj_Travel": "Viagem",
    "emj_Letters": "Letras",
    "emj_Misc": "Diversos",
    "create_empty_project_templates": "Criar um Projeto Vazio a partir de Modelos",
    "days_ago": "dias atrás",
    "create_workspace": "Criar Espaço de Trabalho",
    "workspace": "Espaços de Trabalho",
    "delete_workspace": "Deletar Espaço de Trabalho",
    "transfer_owner": "Transferir Proprietário",
    "can_create_new_workspace": "Pode criar novo espaço de trabalho?",
    "could_delete_workspace": "Poderia deletar o espaço de trabalho?",
    "available_subtitles_time": "Tempo de Legendas Disponível",
    "available_storage_space": "Espaço de Armazenamento Disponível",
    "available_text_to_speech": "Texto para Fala Disponível",
    "other_workspaces": "Outros espaços de trabalho",
    "members": "Membros",
    "add_member": "Adicionar Membro",
    "remove_member": "Remover Membro",
    "change_member_level": "Mudar Nível do Membro",
    "owner": "Proprietário",
    "admin": "Administrador",
    "member": "Membro",
    "read_only": "Somente Leitura",
    "duplicate": "Duplicar",
    "splite": "Dividir",
    "remove": "remover",
    "layer": "Camada",
    "volume": "Volume",
    "disable": "Desativar",
    "deleterow": "DeletarLinha",
    "fullscreen": "Tela Cheia",
    "editsubtitle": "EditarLegenda",
    "fit": "Ajustar",
    "green_screen": "Tela Verde",
    "profile": "Perfil",
    "pricing": "Preços",
    "login": "Entrar",
    "logout": "Sair",
    "get_started_free": "Comece - é grátis",
    "dashboard": "Painel",
    "projects": "Projetos",
    "menu_Features": "Recursos",
    "menu_Tools": "Ferramentas",
    "menu_VideoEditor": "Editor de Vídeo",
    menu_AIVideoEditor: "Editor de Vídeo IA",
    "menu_AddImgToVideo": "Adicionar Imagem ao Vídeo",
    "menu_AddMusicToVideo": "Adicionar Música ao Vídeo",
    "menu_AddTextToVideo": "Adicionar Texto ao Vídeo",
    "menu_FilterVideo": "Filtrar Vídeo",
    "menu_AddTransitionstoVideo": "Adicionar Transições ao Vídeo",
    "menu_AddFreeStockVideos": "Adicionar Vídeos de Stock Gratuitos",
    "menu_SubtitlesTranscription": "Legendas & Transcrição",
    "menu_AddSubtitlesToVideo": "Adicionar Legendas ao Vídeo",
    "menu_AudioToText": "Áudio para Texto",
    "menu_AutoSubtitles": "Legendas Automáticas",
    "menu_TranscribeVideo": "Transcrever Vídeo",
    "menu_TranslateVideo": "Traduzir Vídeo",
    "menu_Toolkit": "Caixa de Ferramentas",
    "menu_Crop": "Cortar",
    "menu_Cut": "Recortar",
    "menu_JoinMerge": "Juntar/Mesclar",
    "menu_LoopVideo": "Vídeo em Loop",
    "menu_Resize": "Redimensionar",
    "menu_Rotate": "Rotacionar",
    "menu_More": "Mais",
    "menu_VideoCompressor": "Compressor de Vídeo",
    "menu_VideoConverter": "Conversor de Vídeo",
    "menu_AIGenerated": "Gerado por IA",
    "menu_AIGeneratedImages": "Imagens Geradas por IA",
    "menu_AIGeneratedVideos": "Vídeos Gerados por IA",
    "menu_AIGeneratedVoices": "Vozes Geradas por IA",
    "menu_Blog": "Blog",
    "menu_VIDEOOBlog": "Blog VIDEOO",
    "menu_VIDEOOHowTo": "Como fazer VIDEOO",
    "menu_VIDEOOCareers": "Carreiras VIDEOO",
    "online_ai_video_editor": "Editor de Vídeo Online Baseado em IA",
    "online_could_video_editor": "Editor de Vídeo Online Baseado em Nuvem",
    "home_free_trial_detail": 'Teste grátis de 7 dias, cancele a qualquer momento',
    "home_create_button_detail": "Criação de vídeo com um clique com legendas automáticas, tradução e mais",
    "create_now": "Painel",
    "home_templates_start": "Explore uma gama de modelos para começar",
    "try_now_required": "Experimente agora, não é necessário conta",
    "online_collaborative_video_editing": "Edição de Vídeo Colaborativa Online",
    "online_collaborative_video_editing_detail": "Mergulhe no mundo da Edição de Vídeo Online Baseada em Nuvem com videoo.io. Nossa plataforma revoluciona a maneira como você edita, oferecendo capacidades de Edição de Vídeo Colaborativa Online e um Espaço de Trabalho de Edição de Vídeo Baseado em Nuvem.",
    "auto_subtitles_translations": "Legendas e Traduções Automáticas",
    "auto_subtitles_translations_detail": "Aprimore seus vídeos com nosso recurso de Legendas e Traduções Automáticas, parte do nosso Software de Edição de Vídeo Online Profissional. Projetado para ser uma Ferramenta de Software de Edição de Vídeo para Todos, videoo.io torna simples criar conteúdo acessível e cativante.",
    "ai_base_text_speech": "Texto para Fala Baseado em IA",
    "ai_base_text_speech_detail": "Nossas Vozes de Texto para Fala Baseadas em IA são perfeitas para criadores de conteúdo, encarnando o conceito de Edição de Vídeo Reinventada para Youtubers e Influenciadores. Com videoo.io, transforme seus scripts em voiceovers envolventes, adicionando um toque profissional aos seus vídeos e simplificando seu processo de produção.",
    "free_user_stock_video": "Vídeos de Stock Gratuitos para Usar",
    "free_user_stock_video_detail": "Acesse uma vasta coleção de Vídeos de Stock Gratuitos para Usar com videoo.io, complementando nossas capacidades de Edição de Vídeo 4K Online. Nossa plataforma, sendo um Software de Edição de Vídeo 4K para Todos, garante que seus projetos se destaquem com filmagens de alta qualidade, tornando a edição de nível profissional acessível a todos os usuários.",
    "share": "Compartilhar",
    "unlisted_video": "Vídeo Não Listado",
    "your_video_about_ready": "Seu vídeo está quase pronto",
    "hours": "Horas",
    "minutes": "Minutos",
    "seconds": "Segundos",
    "want_free_stuff": "Quer coisas grátis?",
    "record_screen_webcam_both": "Grave sua tela, webcam ou ambos",
    "add_subtitle_your_video": "Adicione legendas aos seus vídeos",
    "create_your_team_workspace": "Crie seu Espaço de Trabalho em Equipe",
    "collaborate_videos": "Colabore em vídeos",
    "generate_transcript": "Gerar transcrição",
    "singup_get_all_this_more": "Inscreva-se (de graça) para obter tudo isso e mais!",
    "downloading": "Baixando",
    "download": "Baixar",
    "reedit_video": "Reeditar vídeo",
    "remove_watermark": "Remover marca d'água",
    "already_have_account": "Já tem uma conta?",
    "link_copied": "Link Copiado",
    "invitation_list": "Lista de Convites",
    "remove_invitation": "Remover Convite",
    "change_workspace": "Mudar espaço de trabalho",
    "you_login_manage_your_profile": "Você precisa fazer login para gerenciar seu perfil",
    "you_dont_have_content_here": "Você não tem nenhum conteúdo aqui.",
    "name": "Nome",
    "status": "Status",
    "data_modified": "Data Modificada",
    "all_workspaces": "Todos os Espaços de Trabalho",
    "delete_project": "Deletar Projeto",
    "duplicate_project": "Duplicar Projeto",
    "draft": "Rascunho",
    "edit": "Editar",
    "surname": "Sobrenome",
    "email": "E-mail",
    "plan": "Plano",
    "market_emails": "E-mails de Marketing",
    "editor_notification": "Notificações do Editor",
    "storage": "Armazenamento",
    "subtitle": "Legenda",
    "delete_account": "Deletar Conta",
    "this_action_irreversibe": "Esta ação é irreversível",
    "current_plan": "PLANO ATUAL",
    "cancel_subscription": "Cancelar Assinatura",
    "payment_method": "MÉTODOS DE PAGAMENTO",
    "expires": "Expira",
    "make_default": "Tornar Padrão",
    "delete": "Deletar",
    "add_payment_method": "Adicionar Método de Pagamento",
    "billing_information": "INFORMAÇÕES DE FATURAMENTO",
    "invoice_history": "HISTÓRICO DE FATURAS",
    "payment": "Pagamento",
    "preferences": "Preferências",
    "save_up_to": "Economize Até",
    "your_plan": "Seu Plano",
    "inactive": "Inativo",
    "contact_sales": "Contatar Vendas",
    "need_additional_features": "Precisa de recursos adicionais? Pergunte-nos sobre nosso plano Empresarial"
}
