export const editorLangFrStrs = {
    "welcomNewProject": "Bienvenue dans votre nouveau projet",
    "pls_add_media": "Veuillez ajouter des médias à votre projet",
    "add_video": "Ajouter une vidéo",
    "add_audio": "Ajouter de l'audio",
    "add_image": "Ajouter une image",
    "add_webcam": "Capture Webcam",
    "add_screen": "Capture d'écran",
    "add_webcam_screen": "Capture Webcam & Écran",
    "capture_screen_camera": "Capturez votre écran & caméra !",
    "video_captureDevice": "Dispositif de capture vidéo",
    "resolution": "Résolution",
    "audio_captureDevice": "Dispositif de capture audio",
    "resize_video": "Redimensionner la vidéo",
    "rotate_video": "Pivoter la vidéo",
    "loop_video": "Boucler la vidéo",
    "joinmarge_video": "Ajouter Joindre/Fusionner Vidéo",
    "cut_video": "Couper la vidéo",
    "crop_video": "Rogner la vidéo",
    "translate_video": "Traduire la vidéo",
    "transcripbe_video": "Transcrire la vidéo",
    "auto_subtitles": "Sous-titres automatiques",
    "add_subtitle_video": "Ajouter des sous-titres à la vidéo",
    "compress_video": "Compresser la vidéo",
    "convert_video": "Convertir la vidéo",
    "project_not_accessible": "Projet non accessible",
    "leave": "Quitter",
    "cancel": "Annuler",
    "okay": "OK",
    "stop": "Arrêter",
    "record": "Enregistrer",
    "ongoing_upload_project_str": "Un téléchargement est en cours pour ce projet.",
    "leaving_page_sure": "Vous êtes sur le point de quitter cette page, êtes-vous sûr ?",
    "uploading_files": "Téléchargement des fichiers",
    "record_screen_and_upload": "Enregistrez votre écran et téléchargez.",
    "show_defaul_sharingMenu": "Afficher les éléments de menu de partage par défaut",
    "prefer_share_browser": "Préférer partager un onglet de navigateur",
    "prefer_share_window": "Préférer partager une fenêtre",
    "prefer_share_entireScreen": "Préférer partager un écran entier",
    "record_webcam_and_upload": "Enregistrez votre webcam et téléchargez.",
    "sign_signup_to_uploadVideos": "Veuillez vous connecter ou vous inscrire pour télécharger des vidéos",
    "sizelimir_or_timelimit": "plus grand que 250 Mo ou plus long que 5 minutes",
    "screen_capture_preferences": "Préférences de capture d'écran",
    "is_uploading": "Est en cours de téléchargement",
    "monthly": "Mensuel",
    "annually": "Annuel",
    "most_popular": "Le plus populaire",
    "export": "Exporter",
    "bitrate": "Débit binaire",
    "frame_rate_fps": "Taux de rafraîchissement (FPS)",
    "produce": "Produire",
    "setup": "Configuration",
    "template": "Modèle",
    "templates": "Modèles",
    "media": "Médias",
    "stock": "Stock",
    "translations": "Traductions",
    "subtitles": "Sous-titres",
    "text_to_speech": "Texte en parole",
    "transitions": "Transitions",
    "text": "Texte",
    "emojis": "Émojis",
    "filters": "Filtres",
    "draw": "Dessiner",
    "edit_subtitle": "Éditer le sous-titre",
    "add_new_subtitle": "ajouter un nouveau sous-titre",
    "select_brush_stock_start_draw": "Sélectionnez un coup de pinceau pour commencer à dessiner",
    "brush_stroke": "Coup de pinceau",
    "round": "Rond",
    "sliced": "Tranché",
    "color": "Couleur",
    "opacity": "Opacité",
    "size": "Taille",
    "duration": "Durée",
    "add_new_line": "Ajouter une nouvelle ligne",
    "all_types": "Tous les types",
    "load_more": "Charger plus",
    "upload_video": "Télécharger Vidéo",
    "upload_audio": "Télécharger Audio",
    "upload_image": "Télécharger Image",
    "create_webcam_recording": "Créer un enregistrement Webcam",
    "create_screen_capture_recording": "Créer un enregistrement de capture d'écran",
    "create_webcam_screen_recording": "Créer un enregistrement Webcam & Écran",
    "project_asset": "Actif du projet",
    "you_want_add_translations": "Comment souhaitez-vous ajouter des traductions ?",
    "uploader": "Téléverseur",
    "auto": "AUTO",
    "auto_translations": "Traductions automatiques",
    "automatically_add_transllations_video": "Ajouter automatiquement des traductions à la vidéo",
    "wait_moments_subtitles_being": "Attendre quelques instants pendant que le sous-titre est préparé",
    "select_language_detect": "Sélectionner la langue à détecter",
    "disable_translate": "Désactiver la traduction",
    "select_language_select": "Sélectionner la langue pour la traduction",
    "transition_duration": "Durée de la transition",
    "coming_soon": "Bientôt disponible",
    "language": "Langue",
    "speaking_rate_defaul": "Débit de parole (par défaut 1)",
    "pitch_defaul": "Hauteur (par défaut 0)",
    "volume_gain_default": "Gain de volume (par défaut 0)",
    "create_text_speech": "Créer du texte en parole",
    "add_text": "Ajouter du texte",
    "text": "Texte",
    "start": "Début",
    "end": "Fin",
    "text_style": "Style de texte",
    "background_color": "Couleur de fond",
    "add_new_text_field": "Ajouter un nouveau champ de texte",
    "how_add_subtitles": "Comment souhaitez-vous ajouter des sous-titres",
    "automatically_subtitles_video": "Ajouter automatiquement des sous-titres à la vidéo",
    "text_color": "Couleur du texte",
    "preview_text": "Aperçu du texte",
    "search": "Recherche",
    "orientation": "Orientation",
    "next": "Suivant",
    "previous": "Précédent",
    "locale": "Local",
    "landscape": "Paysage",
    "portrait": "Portrait",
    "square": "Carré",
    "time": "Temps",
    "background": "Arrière-plan",
    "project_setting": "Paramètres du projet",
    "use_template_project": "Utiliser comme modèle de projet",
    "visibility": "Visibilité",
    "category": "Catégorie",
    "capture_thumbnail": "Capturer la vignette",
    "brand_kit": "Kit de marque",
    "video_placeholder": "Espace réservé vidéo2.mp4",
    "replace_video": "Remplacer la vidéo",
    "transformations": "Transformations",
    "fill_canvas": "Remplir le canevas",
    "fit_canvas": "Adapter au canevas",
    "round_corners": "Coins arrondis",
    "audio": "Audio",
    "detach_audio": "Détacher l'audio",
    "cleanAudio": "NettoyerAudio",
    "upgrade_basic_unlock": "Mettre à niveau vers BASIC pour déverrouiller",
    "remove_background_noise_enhance_audio_qty": "Supprimer le bruit de fond et améliorer la qualité audio",
    "color_correction": "Correction des couleurs",
    "reset": "Réinitialiser",
    "exposure": "Exposition",
    "brightness": "Luminosité",
    "contract": "Contraste",
    "saturation": "Saturation",
    "sharpen": "Netteté",
    "blur": "Flou",
    "noise": "Bruit",
    "trending": "TENDANCE",
    "custom": "PERSONNALISÉ",
    "portrait": "PORTRAIT",
    "landscape": "PAYSAGE",
    "settings": "Paramètres",
    "magic_draw": "Dessin magique",
    "tools": "Outils",
    "brush_size": "Taille du pinceau",
    "save": "Sauvegarder",
    "clear": "Effacer",
    "view_all": "Voir tout",
    "save_your_project_later": "Sauvegardez votre projet pour plus tard",
    "sign_in": "se connecter",
    "sign_up": "s'inscrire",
    "sign_up_home": "S'inscrire",
    "redo": "REFAIRE",
    "undo": "ANNULER",
    "collaborate": "COLLABORER",
    "enter_email_share_project": "Entrez l'email pour partager le projet",
    "share_project": "Partager le projet",
    "create_new_workspace": "Créer un nouvel espace de travail",
    "audio_noise_remove": "Suppression du bruit audio",
    "media_upload": "Téléversement de médias",
    "draw_color": "Couleur de dessin",
    "tsl_none": "aucun",
    "tsl_fade": "Fondu",
    "tsl_book_flip": "Feuilletage de livre",
    "tsl_disolve": "Dissoudre",
    "tsl_doorway": "Porte",
    "tsl_blur": "Flou",
    "tsl_push": "Pousser",
    "tsl_slide": "Glisser",
    "tsl_wipe": "Essuyer",
    "tsl_zoom_in": "Zoom avant",
    "tsl_zoom_out": "Zoom arrière",
    "flt_None": "Aucun",
    "flt_Darker": "Plus sombre",
    "flt_BlacknWhite": "Noir et Blanc",
    "flt_Daphne": "Daphné",
    "flt_Penelope": "Pénélope",
    "flt_Phoebe": "Phoebé",
    "flt_Thalia": "Thalie",
    "flt_Chloe": "Chloé",
    "flt_Hermione": "Hermione",
    "flt_Hera": "Héra",
    "flt_Rhea": "Rhéa",
    "flt_Donna": "Donna",
    "flt_Martina": "Martina",
    "flt_Gabriella": "Gabriella",
    "flt_Elisabetta": "Elisabetta",
    "flt_Isabella": "Isabella",
    "flt_Luna": "Luna",
    "flt_Viola": "Viola",
    "flt_Zita": "Zita",
    "flt_Donata": "Donata",
    "flt_Maddalena": "Maddalena",
    "flt_Tecla": "Técla",
    "flt_Narcisa": "Narcisa",
    "emj_Smileys": "Sourires",
    "emj_Animals": "Animaux",
    "emj_People": "Personnes",
    "emj_Jobs": "Métiers",
    "emj_Sports": "Sports",
    "emj_Flags": "Drapeaux",
    "emj_Signs": "Signes",
    "emj_Nature": "Nature",
    "emj_Travel": "Voyage",
    "emj_Letters": "Lettres",
    "emj_Misc": "Divers",
    "create_empty_project_templates": "Créer un projet vide à partir des modèles",
    "days_ago": "il y a des jours",
    "create_workspace": "Créer un espace de travail",
    "workspace": "Espaces de travail",
    "delete_workspace": "Supprimer l'espace de travail",
    "transfer_owner": "Transférer le propriétaire",
    "can_create_new_workspace": "Pouvez-vous créer un nouvel espace de travail ?",
    "could_delete_workspace": "Pourriez-vous supprimer l'espace de travail ?",
    "available_subtitles_time": "Temps de sous-titres disponible",
    "available_storage_space": "Espace de stockage disponible",
    "available_text_to_speech": "Texte en parole disponible",
    "other_workspaces": "Autres espaces de travail",
    "members": "Membres",
    "add_member": "Ajouter un membre",
    "remove_member": "Supprimer un membre",
    "change_member_level": "Changer le niveau du membre",
    "owner": "Propriétaire",
    "admin": "Administrateur",
    "member": "Membre",
    "read_only": "Lecture seule",
    "duplicate": "Dupliquer",
    "splite": "Diviser",
    "remove": "supprimer",
    "layer": "Couche",
    "volume": "Volume",
    "disable": "Désactiver",
    "deleterow": "SupprimerLigne",
    "fullscreen": "Plein écran",
    "editsubtitle": "ÉditerSous-titre",
    "fit": "Ajuster",
    "green_screen": "Fond vert",
    "profile": "Profil",
    "pricing": "Tarification",
    "login": "Connexion",
    "logout": "Déconnexion",
    "get_started_free": "Commencez, c'est gratuit",
    "dashboard": "Tableau de bord",
    "projects": "Projets",
    "menu_Features": "Fonctionnalités",
    "menu_Tools": "Outils",
    "menu_VideoEditor": "Éditeur de vidéo",
    "menu_AIVideoEditor": "Éditeur de vidéo IA",
    "menu_AddImgToVideo": "Ajouter une image à la vidéo",
    "menu_AddMusicToVideo": "Ajouter de la musique à la vidéo",
    "menu_AddTextToVideo": "Ajouter du texte à la vidéo",
    "menu_FilterVideo": "Filtrer la vidéo",
    "menu_AddTransitionstoVideo": "Ajouter des transitions à la vidéo",
    "menu_AddFreeStockVideos": "Ajouter des vidéos de stock gratuites",
    "menu_SubtitlesTranscription": "Sous-titres & Transcription",
    "menu_AddSubtitlesToVideo": "Ajouter des sous-titres à la vidéo",
    "menu_AudioToText": "Audio en texte",
    "menu_AutoSubtitles": "Sous-titres automatiques",
    "menu_TranscribeVideo": "Transcrire la vidéo",
    "menu_TranslateVideo": "Traduire la vidéo",
    "menu_Toolkit": "Boîte à outils",
    "menu_Crop": "Rogner",
    "menu_Cut": "Couper",
    "menu_JoinMerge": "Joindre/Fusionner",
    "menu_LoopVideo": "Boucler la vidéo",
    "menu_Resize": "Redimensionner",
    "menu_Rotate": "Pivoter",
    "menu_More": "Plus",
    "menu_VideoCompressor": "Compresseur de vidéo",
    "menu_VideoConverter": "Convertisseur de vidéo",
    "menu_AIGenerated": "Généré par IA",
    "menu_AIGeneratedImages": "Images générées par IA",
    "menu_AIGeneratedVideos": "Vidéos générées par IA",
    "menu_AIGeneratedVoices": "Voix générées par IA",
    "menu_Blog": "Blog",
    "menu_VIDEOOBlog": "Blog VIDEOO",
    "menu_VIDEOOHowTo": "Comment faire VIDEOO",
    "menu_VIDEOOCareers": "Carrières VIDEOO",
    "online_ai_video_editor": "Éditeur de vidéo en ligne basé sur l'IA",
    "online_could_video_editor": "Éditeur de vidéo en ligne basé sur le cloud",
    "home_free_trial_detail": 'Essai gratuit de 7 jours, annulation à tout moment',
    "home_create_button_detail": "Création de vidéo en un clic avec sous-titres automatiques, traduction, et plus encore",
    "create_now": "Tableau de bord",
    "home_templates_start": "Explorez une gamme de modèles pour commencer",
    "try_now_required": "Essayez maintenant, aucun compte requis",
    "online_collaborative_video_editing": "Édition vidéo collaborative en ligne",
    "online_collaborative_video_editing_detail": "Plongez dans le monde de l'édition vidéo en ligne basée sur le cloud avec videoo.io. Notre plateforme révolutionne votre manière d'éditer, offrant des capacités d'édition vidéo collaborative en ligne et un espace de travail d'édition vidéo basé sur le cloud.",
    "auto_subtitles_translations": "Sous-titres et traductions automatiques",
    "auto_subtitles_translations_detail": "Améliorez vos vidéos avec notre fonctionnalité de sous-titres et traductions automatiques, une partie de notre logiciel professionnel d'édition vidéo en ligne. Conçu pour être un outil de logiciel d'édition vidéo pour tout le monde, videoo.io rend simple la création de contenu accessible et accrocheur.",
    "ai_base_text_speech": "Texte en parole basé sur l'IA",
    "ai_base_text_speech_detail": "Nos voix de texte en parole basées sur l'IA sont parfaites pour les créateurs de contenu, incarnant le concept de l'édition vidéo réinventée pour les Youtubers et les influenceurs. Avec videoo.io, transformez vos scripts en voix off engageantes, ajoutant une touche professionnelle à vos vidéos et rationalisant votre processus de production.",
    "free_user_stock_video": "Vidéos de stock gratuites à utiliser",
    "free_user_stock_video_detail": "Accédez à une vaste collection de vidéos de stock gratuites à utiliser avec videoo.io, complétant nos capacités d'édition vidéo 4K en ligne. Notre plateforme, étant un logiciel d'édition vidéo 4K pour tout le monde, assure que vos projets se démarquent avec des images de haute qualité, rendant l'édition de niveau professionnel accessible à tous les utilisateurs.",
    "share": "Partager",
    "unlisted_video": "Vidéo non répertoriée",
    "your_video_about_ready": "Votre vidéo est sur le point d'être prête",
    "hours": "Heures",
    "minutes": "Minutes",
    "seconds": "Secondes",
    "want_free_stuff": "Vous voulez des trucs gratuits ?",
    "record_screen_webcam_both": "Enregistrez votre écran, webcam, ou les deux",
    "add_subtitle_your_video": "Ajoutez des sous-titres à vos vidéos",
    "create_your_team_workspace": "Créez votre espace de travail d'équipe",
    "collaborate_videos": "Collaborez sur des vidéos",
    "generate_transcript": "Générer un transcript",
    "singup_get_all_this_more": "Inscrivez-vous (gratuitement) pour obtenir tout cela et plus encore !",
    "downloading": "Téléchargement",
    "download": "Télécharger",
    "reedit_video": "Ré-éditer la vidéo",
    "remove_watermark": "Supprimer le filigrane",
    "already_have_account": "Vous avez déjà un compte ?",
    "link_copied": "Lien copié",
    "invitation_list": "Liste d'invitation",
    "remove_invitation": "Supprimer l'invitation",
    "change_workspace": " Changer d'espace de travail",
    "you_login_manage_your_profile": "Vous devez vous connecter pour gérer votre profil",
    "you_dont_have_content_here": "Vous n'avez aucun contenu ici.",
    "name": "Nom",
    "status": "Statut",
    "data_modified": "Date de modification",
    "all_workspaces": "Tous les espaces de travail",
    "delete_project": "Supprimer le projet",
    "duplicate_project": "Dupliquer le projet",
    "draft": "Brouillon",
    "edit": "Éditer",
    "surname": "Nom de famille",
    "email": "Email",
    "plan": "Plan",
    "market_emails": "Emails marketing",
    "editor_notification": "Notifications de l'éditeur",
    "storage": "Stockage",
    "subtitle": "Sous-titre",
    "delete_account": "Supprimer le compte",
    "this_action_irreversibe": "Cette action est irréversible",
    "current_plan": "PLAN ACTUEL",
    "cancel_subscription": "Annuler l'abonnement",
    "payment_method": "MÉTHODES DE PAIEMENT",
    "expires": "Expire",
    "make_default": "Définir par défaut",
    "delete": "Supprimer",
    "add_payment_method": "Ajouter une méthode de paiement",
    "billing_information": "INFORMATIONS DE FACTURATION",
    "invoice_history": "HISTORIQUE DES FACTURES",
    "payment": "Paiement",
    "preferences": "Préférences",
    "save_up_to": "Économisez jusqu'à ",
    "your_plan": "Votre plan",
    "inactive": "Inactif",
    "contact_sales": "Contacter les ventes",
    "need_additional_features": "Besoin de fonctionnalités supplémentaires ? Demandez-nous pour notre plan Entreprise"
};